.App {
  display: flex;
  flex-direction: row;
  height: 100vh;
  background-color: #ffffff;
}

.swagger-ui .info {
  background-color: white;
  padding: 50px;
  border-bottom: 5px solid #003764;
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%), 0 2px 5px rgb(0 0 0 / 20%);
}

/*
.swagger-ui .info .description p {
  color: black;
}

.swagger-ui .info .description .markdown {
  color: black;
}

.swagger-ui .scheme-container {
  background-color: rgba(1, 1, 1, 0);
  box-shadow: 0 0 0 0;
}

.swagger-ui .schemes-title {
  color: white;
}

.swagger-ui .opblock-tag {
  color: white;
  text-transform: uppercase;
  letter-spacing: .1em;
  border-bottom: 1px solid #D4BE7D;
}

.swagger-ui .opblock-tag small {
  color: white;
}

.swagger-ui .op-block {
  border-radius: 0;
}

.swagger-ui .opblock .opblock-summary-path {
  color: white;
}

.swagger-ui .opblock .opblock-summary-description {
  color: white;
}

.swagger-ui .markdown {
  color: white;
}

.swagger-ui .markdown p {
  color: white;
}

.swagger-ui .table-container {
  color: white;
}

.swagger-ui .response-col_status {
  color: white;
}

.swagger-ui .responses-header td {
  color: white;
}

.swagger-ui .table-container th {
  color: white;
}

.swagger-ui .table-container .parameter__name {
  color: white;
}

.swagger-ui section.models {
  border: 1px solid #D4BE7D;
  border-radius: 0;
}

.swagger-ui section.models h4 {
  color: white;
}

.swagger-ui section.models .model-container {
  background-color: white;
  border-radius: 0;
}

.swagger-ui section.models .model-container:hover {
  background-color: whitesmoke;
}
*/